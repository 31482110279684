<template>
    <svg class="header-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_8" x="0px" y="0px" viewBox="0 0 200 200" style="enable-background:new 0 0 200 200; fill: #121820" xml:space="preserve" :width="size + 'px'" :fill="color">
        <g>
        <path class="st0" d="M110.1,89.7c0-4,0-7.7,0-11.4c0-19.5,0-39,0-58.4c0-11.4,7.8-19.3,19.2-19.3c16.9,0,33.7,0,50.6,0   c11.8,0,19.5,7.7,19.6,19.4c0.1,17.1,0.1,34.3,0,51.4c0,10.5-7.9,18.5-18.3,18.6c-22.9,0.1-45.9,0-68.8,0   C111.8,89.9,111.3,89.8,110.1,89.7z"/>
        <path class="st0" d="M89.9,110.6c0,24,0.2,47.9-0.1,71.8c-0.1,9.3-8.3,16.9-17.7,17c-17.8,0.2-35.6,0.2-53.5,0   c-10.1-0.1-17.9-7.9-18-17.9c-0.2-17.8-0.2-35.6,0-53.5c0.1-9.6,7.8-17.7,17.3-17.8c23.6-0.2,47.2,0,70.9,0   C89,110.1,89.3,110.3,89.9,110.6z"/>
        <path class="st0" d="M89.5,89.9c-19.2,0-38.2,0-57.3,0c-4.3,0-8.6,0-12.8,0C8.6,89.7,0.7,82.1,0.6,71.4c-0.1-17.5-0.1-35.1,0-52.6   C0.7,8.5,8.6,0.7,18.9,0.6c17.5-0.1,35.1-0.1,52.6,0c10.2,0.1,18.3,7.6,18.4,17.6c0.2,23.5,0,47,0,70.4   C89.9,88.9,89.7,89.1,89.5,89.9z"/>
        <path class="st0" d="M110.3,110.1c1.6,0,2.9,0,4.3,0c21.5,0,43.1,0,64.6,0c12.9,0,20.2,7.4,20.3,20.4c0,16.4,0,32.9,0,49.3   c0,12.1-7.6,19.6-19.7,19.7c-16.7,0-33.4,0-50.1,0c-11.8,0-19.5-7.7-19.5-19.4c0-22,0-43.9,0-65.9   C110.1,112.9,110.2,111.7,110.3,110.1z"/>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'iconCategories',
    props:{
    size: {
        type: Number,
        default: 20,
        },
    color:{
        type: String,
        default: ''
    }
    }
}
</script>
<style scoped>
    
</style>